export enum ObiQueryParams {
  CURRENT_PAY_PERIOD = 'CURRENT_PAY_PERIOD',
}

export enum FilterControlNames {
  periodBeginDate = 'periodBeginDate',
  periodEndDate = 'periodEndDate',
  madCd = 'madCd',
  customerName = 'customerName',
  incentiveNbr = 'incentiveNbr',
  proNbr = 'proNbr',
}
